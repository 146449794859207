
var ortWasm = (function() {
  var _scriptDir = typeof document !== 'undefined' && document.currentScript ? document.currentScript.src : undefined;
  if (typeof __filename !== 'undefined') _scriptDir = _scriptDir || __filename;
  return (
function(ortWasm) {
  ortWasm = ortWasm || {};


var c;c||(c=typeof ortWasm !== 'undefined' ? ortWasm : {});var aa,g;c.ready=new Promise(function(a,b){aa=a;g=b});var r={},t;for(t in c)c.hasOwnProperty(t)&&(r[t]=c[t]);var v="./this.program",ba="object"===typeof window,w="function"===typeof importScripts,ca="object"===typeof process&&"object"===typeof process.versions&&"string"===typeof process.versions.node,x="",y,z,B,C,D;
if(ca)x=w?require("path").dirname(x)+"/":__dirname+"/",y=function(a,b){C||(C=require("fs"));D||(D=require("path"));a=D.normalize(a);return C.readFileSync(a,b?null:"utf8")},B=function(a){a=y(a,!0);a.buffer||(a=new Uint8Array(a));a.buffer||E("Assertion failed: undefined");return a},z=function(a,b,e){C||(C=require("fs"));D||(D=require("path"));a=D.normalize(a);C.readFile(a,function(f,h){f?e(f):b(h.buffer)})},1<process.argv.length&&(v=process.argv[1].replace(/\\/g,"/")),process.argv.slice(2),process.on("uncaughtException",
function(a){throw a;}),process.on("unhandledRejection",E),c.inspect=function(){return"[Emscripten Module object]"};else if(ba||w)w?x=self.location.href:"undefined"!==typeof document&&document.currentScript&&(x=document.currentScript.src),_scriptDir&&(x=_scriptDir),0!==x.indexOf("blob:")?x=x.substr(0,x.lastIndexOf("/")+1):x="",y=function(a){var b=new XMLHttpRequest;b.open("GET",a,!1);b.send(null);return b.responseText},w&&(B=function(a){var b=new XMLHttpRequest;b.open("GET",a,!1);b.responseType="arraybuffer";
b.send(null);return new Uint8Array(b.response)}),z=function(a,b,e){var f=new XMLHttpRequest;f.open("GET",a,!0);f.responseType="arraybuffer";f.onload=function(){200==f.status||0==f.status&&f.response?b(f.response):e()};f.onerror=e;f.send(null)};var da=c.print||console.log.bind(console),F=c.printErr||console.warn.bind(console);for(t in r)r.hasOwnProperty(t)&&(c[t]=r[t]);r=null;c.thisProgram&&(v=c.thisProgram);var H;c.wasmBinary&&(H=c.wasmBinary);var noExitRuntime=c.noExitRuntime||!1;
"object"!==typeof WebAssembly&&E("no native wasm support detected");var I,ea=!1,fa="undefined"!==typeof TextDecoder?new TextDecoder("utf8"):void 0;
function ha(a,b,e){var f=b+e;for(e=b;a[e]&&!(e>=f);)++e;if(16<e-b&&a.subarray&&fa)return fa.decode(a.subarray(b,e));for(f="";b<e;){var h=a[b++];if(h&128){var k=a[b++]&63;if(192==(h&224))f+=String.fromCharCode((h&31)<<6|k);else{var l=a[b++]&63;h=224==(h&240)?(h&15)<<12|k<<6|l:(h&7)<<18|k<<12|l<<6|a[b++]&63;65536>h?f+=String.fromCharCode(h):(h-=65536,f+=String.fromCharCode(55296|h>>10,56320|h&1023))}}else f+=String.fromCharCode(h)}return f}function J(a,b){return a?ha(K,a,b):""}
function L(a,b,e,f){if(!(0<f))return 0;var h=e;f=e+f-1;for(var k=0;k<a.length;++k){var l=a.charCodeAt(k);if(55296<=l&&57343>=l){var q=a.charCodeAt(++k);l=65536+((l&1023)<<10)|q&1023}if(127>=l){if(e>=f)break;b[e++]=l}else{if(2047>=l){if(e+1>=f)break;b[e++]=192|l>>6}else{if(65535>=l){if(e+2>=f)break;b[e++]=224|l>>12}else{if(e+3>=f)break;b[e++]=240|l>>18;b[e++]=128|l>>12&63}b[e++]=128|l>>6&63}b[e++]=128|l&63}}b[e]=0;return e-h}
function ia(a){for(var b=0,e=0;e<a.length;++e){var f=a.charCodeAt(e);55296<=f&&57343>=f&&(f=65536+((f&1023)<<10)|a.charCodeAt(++e)&1023);127>=f?++b:b=2047>=f?b+2:65535>=f?b+3:b+4}return b}function ja(a){var b=ia(a)+1,e=ka(b);e&&L(a,M,e,b);return e}var la,M,K,N;
function ma(){var a=I.buffer;la=a;c.HEAP8=M=new Int8Array(a);c.HEAP16=new Int16Array(a);c.HEAP32=N=new Int32Array(a);c.HEAPU8=K=new Uint8Array(a);c.HEAPU16=new Uint16Array(a);c.HEAPU32=new Uint32Array(a);c.HEAPF32=new Float32Array(a);c.HEAPF64=new Float64Array(a)}var na,oa=[],pa=[],qa=[],ra=[];function sa(){var a=c.preRun.shift();oa.unshift(a)}var O=0,ta=null,P=null;c.preloadedImages={};c.preloadedAudios={};
function E(a){if(c.onAbort)c.onAbort(a);F(a);ea=!0;a=new WebAssembly.RuntimeError("abort("+a+"). Build with -s ASSERTIONS=1 for more info.");g(a);throw a;}function ua(){return Q.startsWith("data:application/octet-stream;base64,")}var Q;Q="ort-wasm.wasm";if(!ua()){var va=Q;Q=c.locateFile?c.locateFile(va,x):x+va}function wa(){var a=Q;try{if(a==Q&&H)return new Uint8Array(H);if(B)return B(a);throw"both async and sync fetching of the wasm failed";}catch(b){E(b)}}
function xa(){if(!H&&(ba||w)){if("function"===typeof fetch&&!Q.startsWith("file://"))return fetch(Q,{credentials:"same-origin"}).then(function(a){if(!a.ok)throw"failed to load wasm binary file at '"+Q+"'";return a.arrayBuffer()}).catch(function(){return wa()});if(z)return new Promise(function(a,b){z(Q,function(e){a(new Uint8Array(e))},b)})}return Promise.resolve().then(function(){return wa()})}
function ya(a){for(;0<a.length;){var b=a.shift();if("function"==typeof b)b(c);else{var e=b.Ea;"number"===typeof e?void 0===b.xa?na.get(e)():na.get(e)(b.xa):e(void 0===b.xa?null:b.xa)}}}function za(a){this.ya=a-16;this.Na=function(b){N[this.ya+4>>2]=b};this.Ka=function(b){N[this.ya+8>>2]=b};this.La=function(){N[this.ya>>2]=0};this.Ja=function(){M[this.ya+12>>0]=0};this.Ma=function(){M[this.ya+13>>0]=0};this.Ga=function(b,e){this.Na(b);this.Ka(e);this.La();this.Ja();this.Ma()}}
var Aa=0,Ba={},Ca=[null,[],[]],R={},S;S=ca?function(){var a=process.hrtime();return 1E3*a[0]+a[1]/1E6}:function(){return performance.now()};var Da={};function Ea(){if(!Fa){var a={USER:"web_user",LOGNAME:"web_user",PATH:"/",PWD:"/",HOME:"/home/web_user",LANG:("object"===typeof navigator&&navigator.languages&&navigator.languages[0]||"C").replace("-","_")+".UTF-8",_:v||"./this.program"},b;for(b in Da)void 0===Da[b]?delete a[b]:a[b]=Da[b];var e=[];for(b in a)e.push(b+"="+a[b]);Fa=e}return Fa}var Fa;
function T(a,b){a=new Date(1E3*N[a>>2]);N[b>>2]=a.getUTCSeconds();N[b+4>>2]=a.getUTCMinutes();N[b+8>>2]=a.getUTCHours();N[b+12>>2]=a.getUTCDate();N[b+16>>2]=a.getUTCMonth();N[b+20>>2]=a.getUTCFullYear()-1900;N[b+24>>2]=a.getUTCDay();N[b+36>>2]=0;N[b+32>>2]=0;N[b+28>>2]=(a.getTime()-Date.UTC(a.getUTCFullYear(),0,1,0,0,0,0))/864E5|0;T.Da||(T.Da=ja("GMT"));N[b+40>>2]=T.Da;return b}
function Ga(){function a(l){return(l=l.toTimeString().match(/\(([A-Za-z ]+)\)$/))?l[1]:"GMT"}if(!Ka){Ka=!0;var b=(new Date).getFullYear(),e=new Date(b,0,1),f=new Date(b,6,1);b=e.getTimezoneOffset();var h=f.getTimezoneOffset(),k=Math.max(b,h);N[La()>>2]=60*k;N[Ma()>>2]=Number(b!=h);e=a(e);f=a(f);e=ja(e);f=ja(f);h<b?(N[U()>>2]=e,N[U()+4>>2]=f):(N[U()>>2]=f,N[U()+4>>2]=e)}}var Ka;function V(a){return 0===a%4&&(0!==a%100||0===a%400)}function Na(a,b){for(var e=0,f=0;f<=b;e+=a[f++]);return e}
var W=[31,29,31,30,31,30,31,31,30,31,30,31],X=[31,28,31,30,31,30,31,31,30,31,30,31];function Y(a,b){for(a=new Date(a.getTime());0<b;){var e=a.getMonth(),f=(V(a.getFullYear())?W:X)[e];if(b>f-a.getDate())b-=f-a.getDate()+1,a.setDate(1),11>e?a.setMonth(e+1):(a.setMonth(0),a.setFullYear(a.getFullYear()+1));else{a.setDate(a.getDate()+b);break}}return a}
function Oa(a,b,e,f){function h(d,m,n){for(d="number"===typeof d?d.toString():d||"";d.length<m;)d=n[0]+d;return d}function k(d,m){return h(d,m,"0")}function l(d,m){function n(Ha){return 0>Ha?-1:0<Ha?1:0}var A;0===(A=n(d.getFullYear()-m.getFullYear()))&&0===(A=n(d.getMonth()-m.getMonth()))&&(A=n(d.getDate()-m.getDate()));return A}function q(d){switch(d.getDay()){case 0:return new Date(d.getFullYear()-1,11,29);case 1:return d;case 2:return new Date(d.getFullYear(),0,3);case 3:return new Date(d.getFullYear(),
0,2);case 4:return new Date(d.getFullYear(),0,1);case 5:return new Date(d.getFullYear()-1,11,31);case 6:return new Date(d.getFullYear()-1,11,30)}}function G(d){d=Y(new Date(d.va+1900,0,1),d.Ca);var m=new Date(d.getFullYear()+1,0,4),n=q(new Date(d.getFullYear(),0,4));m=q(m);return 0>=l(n,d)?0>=l(m,d)?d.getFullYear()+1:d.getFullYear():d.getFullYear()-1}var u=N[f+40>>2];f={Qa:N[f>>2],Pa:N[f+4>>2],Aa:N[f+8>>2],za:N[f+12>>2],wa:N[f+16>>2],va:N[f+20>>2],Ba:N[f+24>>2],Ca:N[f+28>>2],Ya:N[f+32>>2],Oa:N[f+
36>>2],Ra:u?J(u):""};e=J(e);u={"%c":"%a %b %d %H:%M:%S %Y","%D":"%m/%d/%y","%F":"%Y-%m-%d","%h":"%b","%r":"%I:%M:%S %p","%R":"%H:%M","%T":"%H:%M:%S","%x":"%m/%d/%y","%X":"%H:%M:%S","%Ec":"%c","%EC":"%C","%Ex":"%m/%d/%y","%EX":"%H:%M:%S","%Ey":"%y","%EY":"%Y","%Od":"%d","%Oe":"%e","%OH":"%H","%OI":"%I","%Om":"%m","%OM":"%M","%OS":"%S","%Ou":"%u","%OU":"%U","%OV":"%V","%Ow":"%w","%OW":"%W","%Oy":"%y"};for(var p in u)e=e.replace(new RegExp(p,"g"),u[p]);var Ia="Sunday Monday Tuesday Wednesday Thursday Friday Saturday".split(" "),
Ja="January February March April May June July August September October November December".split(" ");u={"%a":function(d){return Ia[d.Ba].substring(0,3)},"%A":function(d){return Ia[d.Ba]},"%b":function(d){return Ja[d.wa].substring(0,3)},"%B":function(d){return Ja[d.wa]},"%C":function(d){return k((d.va+1900)/100|0,2)},"%d":function(d){return k(d.za,2)},"%e":function(d){return h(d.za,2," ")},"%g":function(d){return G(d).toString().substring(2)},"%G":function(d){return G(d)},"%H":function(d){return k(d.Aa,
2)},"%I":function(d){d=d.Aa;0==d?d=12:12<d&&(d-=12);return k(d,2)},"%j":function(d){return k(d.za+Na(V(d.va+1900)?W:X,d.wa-1),3)},"%m":function(d){return k(d.wa+1,2)},"%M":function(d){return k(d.Pa,2)},"%n":function(){return"\n"},"%p":function(d){return 0<=d.Aa&&12>d.Aa?"AM":"PM"},"%S":function(d){return k(d.Qa,2)},"%t":function(){return"\t"},"%u":function(d){return d.Ba||7},"%U":function(d){var m=new Date(d.va+1900,0,1),n=0===m.getDay()?m:Y(m,7-m.getDay());d=new Date(d.va+1900,d.wa,d.za);return 0>
l(n,d)?k(Math.ceil((31-n.getDate()+(Na(V(d.getFullYear())?W:X,d.getMonth()-1)-31)+d.getDate())/7),2):0===l(n,m)?"01":"00"},"%V":function(d){var m=new Date(d.va+1901,0,4),n=q(new Date(d.va+1900,0,4));m=q(m);var A=Y(new Date(d.va+1900,0,1),d.Ca);return 0>l(A,n)?"53":0>=l(m,A)?"01":k(Math.ceil((n.getFullYear()<d.va+1900?d.Ca+32-n.getDate():d.Ca+1-n.getDate())/7),2)},"%w":function(d){return d.Ba},"%W":function(d){var m=new Date(d.va,0,1),n=1===m.getDay()?m:Y(m,0===m.getDay()?1:7-m.getDay()+1);d=new Date(d.va+
1900,d.wa,d.za);return 0>l(n,d)?k(Math.ceil((31-n.getDate()+(Na(V(d.getFullYear())?W:X,d.getMonth()-1)-31)+d.getDate())/7),2):0===l(n,m)?"01":"00"},"%y":function(d){return(d.va+1900).toString().substring(2)},"%Y":function(d){return d.va+1900},"%z":function(d){d=d.Oa;var m=0<=d;d=Math.abs(d)/60;return(m?"+":"-")+String("0000"+(d/60*100+d%60)).slice(-4)},"%Z":function(d){return d.Ra},"%%":function(){return"%"}};for(p in u)e.includes(p)&&(e=e.replace(new RegExp(p,"g"),u[p](f)));p=Pa(e);if(p.length>b)return 0;
M.set(p,a);return p.length-1}function Pa(a){var b=Array(ia(a)+1);L(a,b,0,b.length);return b}
var Ta={a:function(a){return ka(a+16)+16},c:function(a,b){qa.unshift({Ea:a,xa:b})},d:function(a,b){qa.unshift({Ea:a,xa:b})},b:function(a,b,e){(new za(a)).Ga(b,e);Aa++;throw a;},D:function(a,b){a=J(a);return R.Sa(a,b)},m:function(){return 0},I:function(){},L:function(){},o:function(){return 42},x:function(){return 0},H:function(){},G:function(a,b){a=J(a);return R.Ta(a,b)},K:function(a,b,e,f,h,k){k<<=12;if(0!==(f&16)&&0!==a%65536)b=-28;else if(0!==(f&32)){a=65536*Math.ceil(b/65536);var l=Qa(65536,a);
l?(K.fill(0,l,l+a),a=l):a=0;a?(Ba[a]={Ia:a,Ha:b,Fa:!0,fd:h,Xa:e,flags:f,offset:k},b=a):b=-48}else b=-52;return b},J:function(a,b){var e=Ba[a];0!==b&&e?(b===e.Ha&&(Ba[a]=null,e.Fa&&Ra(e.Ia)),a=0):a=-28;return a},j:function(){},C:function(a,b,e){a=J(a);return R.Ua(a,b,e)},E:function(){},r:function(){},F:function(){},h:function(){E()},p:function(a,b){if(0===a)a=Date.now();else if(1===a||4===a)a=S();else return N[Sa()>>2]=28,-1;N[b>>2]=a/1E3|0;N[b+4>>2]=a%1E3*1E6|0;return 0},s:function(a,b){return a-
b},P:function(){E("To use dlopen, you need to use Emscripten's linking support, see https://github.com/emscripten-core/emscripten/wiki/Linking")},g:function(){E("To use dlopen, you need to use Emscripten's linking support, see https://github.com/emscripten-core/emscripten/wiki/Linking")},Q:function(){E("To use dlopen, you need to use Emscripten's linking support, see https://github.com/emscripten-core/emscripten/wiki/Linking")},O:function(){E("To use dlopen, you need to use Emscripten's linking support, see https://github.com/emscripten-core/emscripten/wiki/Linking")},
M:function(){return 2147483648},v:function(a,b,e){K.copyWithin(a,b,b+e)},i:function(a){var b=K.length;a>>>=0;if(2147483648<a)return!1;for(var e=1;4>=e;e*=2){var f=b*(1+.2/e);f=Math.min(f,a+100663296);f=Math.max(a,f);0<f%65536&&(f+=65536-f%65536);a:{try{I.grow(Math.min(2147483648,f)-la.byteLength+65535>>>16);ma();var h=1;break a}catch(k){}h=void 0}if(h)return!0}return!1},B:function(a){for(var b=S();S()-b<a;);},z:function(a,b){var e=0;Ea().forEach(function(f,h){var k=b+e;h=N[a+4*h>>2]=k;for(k=0;k<f.length;++k)M[h++>>
0]=f.charCodeAt(k);M[h>>0]=0;e+=f.length+1});return 0},A:function(a,b){var e=Ea();N[a>>2]=e.length;var f=0;e.forEach(function(h){f+=h.length+1});N[b>>2]=f;return 0},f:function(){return 0},y:function(a,b){a=1==a||2==a?2:E();M[b>>0]=a;return 0},n:function(a,b,e,f){a=R.Wa(a);b=R.Va(a,b,e);N[f>>2]=b;return 0},u:function(){},q:function(a,b,e,f){for(var h=0,k=0;k<e;k++){for(var l=N[b+8*k>>2],q=N[b+(8*k+4)>>2],G=0;G<q;G++){var u=K[l+G],p=Ca[a];0===u||10===u?((1===a?da:F)(ha(p,0)),p.length=0):p.push(u)}h+=
q}N[f>>2]=h;return 0},w:function(a){var b=Date.now();N[a>>2]=b/1E3|0;N[a+4>>2]=b%1E3*1E3|0;return 0},t:T,l:function(a,b){Ga();a=new Date(1E3*N[a>>2]);N[b>>2]=a.getSeconds();N[b+4>>2]=a.getMinutes();N[b+8>>2]=a.getHours();N[b+12>>2]=a.getDate();N[b+16>>2]=a.getMonth();N[b+20>>2]=a.getFullYear()-1900;N[b+24>>2]=a.getDay();var e=new Date(a.getFullYear(),0,1);N[b+28>>2]=(a.getTime()-e.getTime())/864E5|0;N[b+36>>2]=-(60*a.getTimezoneOffset());var f=(new Date(a.getFullYear(),6,1)).getTimezoneOffset();e=
e.getTimezoneOffset();a=(f!=e&&a.getTimezoneOffset()==Math.min(e,f))|0;N[b+32>>2]=a;a=N[U()+(a?4:0)>>2];N[b+40>>2]=a;return b},k:function(a){Ga();var b=new Date(N[a+20>>2]+1900,N[a+16>>2],N[a+12>>2],N[a+8>>2],N[a+4>>2],N[a>>2],0),e=N[a+32>>2],f=b.getTimezoneOffset(),h=new Date(b.getFullYear(),0,1),k=(new Date(b.getFullYear(),6,1)).getTimezoneOffset(),l=h.getTimezoneOffset(),q=Math.min(l,k);0>e?N[a+32>>2]=Number(k!=l&&q==f):0<e!=(q==f)&&(k=Math.max(l,k),b.setTime(b.getTime()+6E4*((0<e?q:k)-f)));N[a+
24>>2]=b.getDay();N[a+28>>2]=(b.getTime()-h.getTime())/864E5|0;N[a>>2]=b.getSeconds();N[a+4>>2]=b.getMinutes();N[a+8>>2]=b.getHours();N[a+12>>2]=b.getDate();N[a+16>>2]=b.getMonth();return b.getTime()/1E3|0},N:Oa,e:function(a,b,e,f){return Oa(a,b,e,f)}};
(function(){function a(h){c.asm=h.exports;I=c.asm.R;ma();na=c.asm.ua;pa.unshift(c.asm.S);O--;c.monitorRunDependencies&&c.monitorRunDependencies(O);0==O&&(null!==ta&&(clearInterval(ta),ta=null),P&&(h=P,P=null,h()))}function b(h){a(h.instance)}function e(h){return xa().then(function(k){return WebAssembly.instantiate(k,f)}).then(h,function(k){F("failed to asynchronously prepare wasm: "+k);E(k)})}var f={a:Ta};O++;c.monitorRunDependencies&&c.monitorRunDependencies(O);if(c.instantiateWasm)try{return c.instantiateWasm(f,
a)}catch(h){return F("Module.instantiateWasm callback failed with error: "+h),!1}(function(){return H||"function"!==typeof WebAssembly.instantiateStreaming||ua()||Q.startsWith("file://")||"function"!==typeof fetch?e(b):fetch(Q,{credentials:"same-origin"}).then(function(h){return WebAssembly.instantiateStreaming(h,f).then(b,function(k){F("wasm streaming compile failed: "+k);F("falling back to ArrayBuffer instantiation");return e(b)})})})().catch(g);return{}})();
c.___wasm_call_ctors=function(){return(c.___wasm_call_ctors=c.asm.S).apply(null,arguments)};c._OrtInit=function(){return(c._OrtInit=c.asm.T).apply(null,arguments)};c._OrtCreateSessionOptions=function(){return(c._OrtCreateSessionOptions=c.asm.U).apply(null,arguments)};c._OrtAddSessionConfigEntry=function(){return(c._OrtAddSessionConfigEntry=c.asm.V).apply(null,arguments)};c._OrtReleaseSessionOptions=function(){return(c._OrtReleaseSessionOptions=c.asm.W).apply(null,arguments)};
c._OrtCreateSession=function(){return(c._OrtCreateSession=c.asm.X).apply(null,arguments)};c._OrtReleaseSession=function(){return(c._OrtReleaseSession=c.asm.Y).apply(null,arguments)};c._OrtGetInputCount=function(){return(c._OrtGetInputCount=c.asm.Z).apply(null,arguments)};c._OrtGetOutputCount=function(){return(c._OrtGetOutputCount=c.asm._).apply(null,arguments)};c._OrtGetInputName=function(){return(c._OrtGetInputName=c.asm.$).apply(null,arguments)};
c._OrtGetOutputName=function(){return(c._OrtGetOutputName=c.asm.aa).apply(null,arguments)};c._OrtFree=function(){return(c._OrtFree=c.asm.ba).apply(null,arguments)};c._OrtCreateTensor=function(){return(c._OrtCreateTensor=c.asm.ca).apply(null,arguments)};c._OrtGetTensorData=function(){return(c._OrtGetTensorData=c.asm.da).apply(null,arguments)};c._OrtReleaseTensor=function(){return(c._OrtReleaseTensor=c.asm.ea).apply(null,arguments)};
c._OrtCreateRunOptions=function(){return(c._OrtCreateRunOptions=c.asm.fa).apply(null,arguments)};c._OrtAddRunConfigEntry=function(){return(c._OrtAddRunConfigEntry=c.asm.ga).apply(null,arguments)};c._OrtReleaseRunOptions=function(){return(c._OrtReleaseRunOptions=c.asm.ha).apply(null,arguments)};c._OrtRun=function(){return(c._OrtRun=c.asm.ia).apply(null,arguments)};c._OrtEndProfiling=function(){return(c._OrtEndProfiling=c.asm.ja).apply(null,arguments)};
var ka=c._malloc=function(){return(ka=c._malloc=c.asm.ka).apply(null,arguments)},Sa=c.___errno_location=function(){return(Sa=c.___errno_location=c.asm.la).apply(null,arguments)},Ra=c._free=function(){return(Ra=c._free=c.asm.ma).apply(null,arguments)},U=c.__get_tzname=function(){return(U=c.__get_tzname=c.asm.na).apply(null,arguments)},Ma=c.__get_daylight=function(){return(Ma=c.__get_daylight=c.asm.oa).apply(null,arguments)},La=c.__get_timezone=function(){return(La=c.__get_timezone=c.asm.pa).apply(null,
arguments)},Ua=c.stackSave=function(){return(Ua=c.stackSave=c.asm.qa).apply(null,arguments)},Va=c.stackRestore=function(){return(Va=c.stackRestore=c.asm.ra).apply(null,arguments)},Wa=c.stackAlloc=function(){return(Wa=c.stackAlloc=c.asm.sa).apply(null,arguments)},Qa=c._memalign=function(){return(Qa=c._memalign=c.asm.ta).apply(null,arguments)};c.UTF8ToString=J;c.stringToUTF8=function(a,b,e){return L(a,K,b,e)};c.lengthBytesUTF8=ia;c.stackSave=Ua;c.stackRestore=Va;c.stackAlloc=Wa;var Z;
P=function Xa(){Z||Ya();Z||(P=Xa)};
function Ya(){function a(){if(!Z&&(Z=!0,c.calledRun=!0,!ea)){ya(pa);aa(c);if(c.onRuntimeInitialized)c.onRuntimeInitialized();if(c.postRun)for("function"==typeof c.postRun&&(c.postRun=[c.postRun]);c.postRun.length;){var b=c.postRun.shift();ra.unshift(b)}ya(ra)}}if(!(0<O)){if(c.preRun)for("function"==typeof c.preRun&&(c.preRun=[c.preRun]);c.preRun.length;)sa();ya(oa);0<O||(c.setStatus?(c.setStatus("Running..."),setTimeout(function(){setTimeout(function(){c.setStatus("")},1);a()},1)):a())}}c.run=Ya;
if(c.preInit)for("function"==typeof c.preInit&&(c.preInit=[c.preInit]);0<c.preInit.length;)c.preInit.pop()();Ya();


  return ortWasm.ready
}
);
})();
if (typeof exports === 'object' && typeof module === 'object')
  module.exports = ortWasm;
else if (typeof define === 'function' && define['amd'])
  define([], function() { return ortWasm; });
else if (typeof exports === 'object')
  exports["ortWasm"] = ortWasm;
